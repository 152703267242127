/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */

const {component} = require('./cartIconAppConfig');

module.exports = {
  default: {
    component,
  },
};
